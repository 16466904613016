import { useMemo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Listbox } from '@features/effector-form/components/listbox';
import { withControl } from '@features/effector-form/hoc/withControl';
import { SelectControlType } from './types';

export const SelectControl = withControl<SelectControlType>(
  ({ value, setValue, error, label, required, readOnly, disabled, options = [], placeholder, isLoading, style = {} }) => {

    const id = useMemo(() => {
        const key = typeof value === 'object' ? value?.id : value;
        return Array.isArray(options) && options.length > 0 && key
          ? options.find((list) => list.id === key)
          : null;
      }, [options, value]
    );

    return (
      <FormControl required={!readOnly && required} disabled={disabled} style={style}>
        {label ? <FormLabel>{label}</FormLabel> : null}

        <Autocomplete
          value={id}
          options={!options ? [] : options}
          onChange={(event: any, newValue: any) => {
            setValue(newValue?.id || null);
          }}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                readOnly,
                endAdornment: readOnly ? null : (
                  <>
                    {isLoading && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />

        {error ? <FormHelperText error>{error}</FormHelperText> : null}
      </FormControl>
    );
  }
);
